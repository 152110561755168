import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import User from '../../entities/admin/user'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface UserState extends ListState<User> {
    editModel: User;
    activeList: Array<User>;
    loadingBranch: boolean;
}
class UserModule extends ListModule<UserState, any, User>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<User>(),
        loading: false,
        loadingBranch: false,
        editModel: new User(),
        activeList: new Array<User>(),
        path: 'users'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            if (payload.data.roles == undefined && payload.data.rol_id != null)
                payload.data.roles = [payload.data.rol_id];

            payload.data['confirmed'] = '1';

            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (response.data.result)
                context.state.activeList.push(response.data.result);
            return response.data.result;
        },
        async update(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            if (payload.data.roles == undefined && payload.data.rol_id != null)
                payload.data.roles = [payload.data.rol_id];

            await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.activeList = [];
        },
        async updateBranch(context: ActionContext<UserState, any>, payload: any) {
            context.state.loadingBranch = true;
            let response = await Ajax.put('/api/users/branch', payload.data).catch(e => {
                context.state.loadingBranch = false;
                return Promise.reject(e);
            });
            context.state.loadingBranch = false;
            return response.data.result;
        },
        async updateCashier(context: ActionContext<UserState, any>, payload: any) {
            context.state.loadingBranch = true;
            let response = await Ajax.put('/api/users/cashier', payload.data).catch(e => {
                context.state.loadingBranch = false;
                return Promise.reject(e);
            });
            context.state.loadingBranch = false;
            return response.data.result;
        },
        async updateProfile(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            if (payload.data.roles == undefined && payload.data.rol_id != null)
                payload.data.roles = [payload.data.rol_id];

            let response = await Ajax.put('/api/user/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.activeList = [];
        },
        async getAllActive(context: ActionContext<UserState, any>, payload: any) {
            let force = (payload.data != undefined && payload.data['force'] == true ? true : false);
            if (context.state.activeList != undefined && context.state.activeList.length > 0 || (context.state.loading && !force)) return true;
            let params = (payload.data == undefined ? {} : payload.data);
            delete params['force'];
            params.perPage = -1;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            if (context.state.activeList != undefined)
                context.state.activeList = reponse.data.result;
            context.state.loading = false;

            context.state.activeList.forEach(item => {                
                if (item.id == Util.abp.session.userId)
                    item.is_seller = true;
            });    
        },
        async sendResetCode(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.post('/api/auth/send-reset-code', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
        async resetPassword(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;
            let data = payload.data;

            let reponse = await Ajax.post('/api/auth/reset-password', data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: UserState, model: User) {
            state.editModel = Object.assign({});
            state.editModel.active = true;
            state.editModel.bo_menu = 1;
        },
        edit(state: UserState, model: User) {
            state.editModel = model;
            if (typeof (model.branches) == "string") {
                let branches = String(model.branches).split(',');
                state.editModel.branches = [];
                branches.forEach(id => {
                    state.editModel.branches.push(parseInt(id));
                });
            }
        },
    });
}
const userModule = new UserModule();
export default userModule;

