import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseOrderDetail from '../../entities/purchases/purchaseorderdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import PurchaseOrder from '@/store/entities/purchases/purchaseorder';

interface PurchaseOrderDetailState extends ListState<PurchaseOrderDetail>{
    editModel:PurchaseOrderDetail;    
    order:number;    
}
class PurchaseOrderDetailModule extends ListModule<PurchaseOrderDetailState,any,PurchaseOrderDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseOrderDetail>(),
        loading:false,
        editModel: new PurchaseOrderDetail(),
        activeList: new Array<PurchaseOrderDetail>(),
        order: 0,
        path: 'purchase-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseOrderDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PurchaseOrderDetailState, model: PurchaseOrderDetail) {
            state.editModel = model;
            if (!(state.editModel.prices != undefined && state.editModel.prices.length > 0))
                state.editModel.prices = [];

            state.editModel.prices.forEach((item, index) => {
                if (item.profit == 0 || item.profit == null)
                    item.profit = parseFloat(((item.price / state.editModel.product_cost - 1) * 100).toFixed(6));
            });
        },
        init(state: PurchaseOrderDetailState, model: PurchaseOrder) {
            state.editModel = Object.assign({});
            state.list = [];
            if (model != undefined) {
                state.order = model.id;
                state.list = model.details;
            }
        },
        add(state: PurchaseOrderDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
                exist[0].total = exist[0].quantity * exist[0].cost;
                exist[0].total_wt = exist[0].quantity * exist[0].cost_wt
                exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(6));
            } else {
                let detail = new PurchaseOrderDetail();
                detail.purchase_order_id = state.editModel.id;
                detail.product = product;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.tax_id = product.tax_id;
                detail.tax_rate = product.tax_rate;
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol
                detail.code = product.code;
                detail.upc = product.upc;
                detail.reference = product.reference;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.exchange_rate = product['exchange_rate'] != undefined ? product['exchange_rate'] : 1;
                detail.quantity = product.quantity;
                detail.quantity_received = 0;                
                detail.cost_wt = parseFloat(parseFloat(String(product.cost_wt)).toFixed(6));
                detail.cost = product.tax_rate > 0 ? Util.removeTaxes(detail.cost_wt, product.tax_rate, 3) : detail.cost_wt;
                detail.total = detail.quantity * detail.cost;
                detail.total_wt = detail.quantity * detail.cost_wt
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
                detail.stock = product.stock;
                detail.product_cost = product.product_cost;
                detail.service = product['service'];
                detail.updated = false;
                detail.prices = [];

                product.prices.forEach((item, index) => {
                    let price = item.price_wt;
                    let det_cost_wt = Util.convertAmountToCurrency(detail.cost_wt, product['model_currency_id'], item.currency_id);
                    
                    let cost = (detail.product_cost > 0 ? detail.product_cost : det_cost_wt);
                    if (item.profit == 0 || item.profit == null)
                        item.profit = parseFloat(((price / cost - 1) * 100).toFixed(6));

                    price = Util.getPriceByProfit(det_cost_wt, item.profit);

                    if (item.price_wt > price) {
                        price = item.price_wt;
                        item.profit = Util.getProfitByPrice(det_cost_wt, price, 2);
                    }

                    detail.prices.push({
                        price_rule_id: item.price_rule_id,
                        currency_id: item.currency_id,
                        profit: item.profit,
                        price: price,
                        product_price: parseFloat(item.price_wt)
                    });
                    if (detail.profit == 0)
                        detail.profit == item.profit;
                });

                state.list.push(detail);
            }
        },
        updateList(state: PurchaseOrderDetailState, data: any) {
            state.list[data.index].quantity = data.detail.quantity;
            state.list[data.index].cost = data.detail.cost;
            state.list[data.index].cost_wt = data.detail.cost_wt;
            state.list[data.index].taxes = data.detail.taxes;
            state.list[data.index].total = data.detail.total;
            state.list[data.index].total_wt = data.detail.total_wt;
            state.list[data.index].prices = data.detail.prices;
            state.list[data.index].updated = true;
            //state.list[data.index].prices.forEach((item, index) => {
            //    let dat = Util.findByParam(data.detail.prices, 'price_rule_id', item.price_rule_id)[0]
            //    item.profit = parseFloat(dat.profit);
            //    item.price = parseFloat(dat.price);
            //});
        },
        setCombinationPrices(state: PurchaseOrderDetailState, item: any) {
            if (item.prices != null) {
                state.list.forEach(data => {
                    if (data.product_id == item.product_id) {
                        data.prices.forEach((pr, index) => {
                            let dat = Util.findByParam(item.prices, 'price_rule_id', pr.price_rule_id)[0]
                            pr.profit = parseFloat(dat.profit);
                            pr.price = parseFloat(dat.price);
                        });
                    }
                });
            }
        },        
        delete(state: PurchaseOrderDetailState, index: number) {
            state.list.splice(index, 1);
        },
    });
}
const orderdetailModule=new PurchaseOrderDetailModule();
export default orderdetailModule;