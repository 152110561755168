import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Cashier from '../../entities/cashiers/cashier'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CashierState extends ListState<Cashier>{
    editModel:Cashier;    
    openCashier:Cashier;    
    isOpen:boolean;    
    loadingOpen: boolean;
    flow: Array<any>;
}
class CashierModule extends ListModule<CashierState,any,Cashier>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Cashier>(),
        loading:false,
        loadingOpen:false,
        isOpen:false,
        editModel: new Cashier(),
        openCashier: new Cashier(),
        activeList: new Array<Cashier>(),
        flow: new Array<any>(),
        path: 'cashiers'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getOpenCashier(context: ActionContext<CashierState, any>, payload: any) {
            context.state.loadingOpen = true;

            if (payload.data == undefined && context.state.editModel.id != undefined && context.state.editModel.id > 0)
                payload.data = { cashier: context.state.editModel.id };

            let reponse = await Ajax.get('/api/cashier-opened', { params: payload.data}).catch(e => {
                context.state.loadingOpen = false;
                return Promise.reject(e);
            });

            let curr = reponse.data.result as Cashier;
            context.state.loadingOpen = false;
            context.state.isOpen = curr.active;
            context.state.editModel = curr;
            context.state.openCashier = (curr.active ? curr : null);
        },
        async getFinancialFlow(context: ActionContext<CashierState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/reports/financial-flow', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.loading = false;
            context.state.flow = response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations        
        new(state: CashierState, model: Cashier) {
            state.editModel = Object.assign({});
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
        },
        view(state: CashierState, model: Cashier) {
            state.editModel = model;
            state.isOpen = model.active;
        },
        setOpenCashier(state: CashierState, cashier: Cashier) {
            state.isOpen = cashier.active;
            state.editModel = cashier;
            state.openCashier = (cashier.active ? cashier : null);
        },
        openCashier(state: CashierState, active: boolean) {
            state.editModel.active = true;
            state.isOpen = true;

            state.activeList.forEach(a => {
                if (a.id == state.editModel.id) {
                    a.active = state.editModel.active;
                    a.history = state.editModel.history;
                }
            });
        },
        closeCashier(state: CashierState, active: boolean) {
            state.editModel.active = false;
            state.isOpen = false;

            state.activeList.forEach(a => {
                if (a.id == state.editModel.id) {
                    a.active = state.editModel.active;
                    a.history = state.editModel.history;
                }
            });
        },
    });
}
const cashierModule=new CashierModule();
export default cashierModule;