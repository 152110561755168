var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-widget',{attrs:{"title":"Lista de Productos","badge":_vm.totalCount,"simpleToolbar":true,"icon":"shopping_cart"}},[(_vm.allowEdit)?_c('div',{attrs:{"slot":"widget-header-action"},slot:"widget-header-action"},[_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['alt', 'p']),expression:"['alt', 'p']"}],attrs:{"text":"","small":"","color":"success","disabled":_vm.disabledAdd},on:{"click":_vm.createProduct,"shortkey":_vm.createProduct}},[_c('v-icon',{attrs:{"left":"","color":"success"}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.L('Agregar Producto'))+" ")],1)],1):_vm._e(),_c('div',{staticClass:"mb-12",attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('v-data-table',{staticClass:"elevation-0 striped products-details",attrs:{"headers":_vm.columns,"items":_vm.list,"item-key":"number","group-by":_vm.groupBy,"options":_vm.options,"loading":_vm.loading,"show-select":"","loading-text":"Cargando... Por favor Espere","disable-pagination":_vm.totalCount < 11,"hide-default-footer":_vm.totalCount < 11,"dense":"","footer-props":{
                              itemsPerPageOptions: [10,20,50,-1],
                              itemsPerPageText: 'Registros por Pagina',
                              showFirstLastPage: true,
                            }},on:{"update:options":function($event){_vm.options=$event},"input":_vm.onSelecteItems},scopedSlots:_vm._u([(_vm.loading)?{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@5"}})]},proxy:true}:null,{key:"header",fn:function(ref){
                            var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"7"}},[_c('v-radio-group',{attrs:{"dense":"","row":"","label":"Agrupar por"},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}},[_c('v-radio',{attrs:{"label":"Proveedor","value":"supplier_id"}}),_c('v-radio',{attrs:{"label":"Categoria","value":"category_id"}}),_c('v-radio',{attrs:{"label":"Marca","value":"manufacturer_id"}})],1)],1),_c('th',{staticStyle:{"text-align":"center","background":"rgb(215 215 215)"},attrs:{"colspan":"5"}},[_vm._v("CANTIDADES")]),_c('th'),_c('th',[_c('v-select',{staticClass:"filterState",staticStyle:{"max-width":"150px"},attrs:{"items":_vm.statesList,"dense":"","hide-details":"","clearable":"","menu-props":"auto","item-text":"name","item-value":"id"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1)])])]}},{key:"group.header",fn:function(ref){
                            var items = ref.items;
                            var isOpen = ref.isOpen;
                            var toggle = ref.toggle;
return [(_vm.groupBy == 'supplier_id')?_c('td',{staticStyle:{"background-color":"#E0F7FA"},attrs:{"colspan":"13"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Proveedor:")]),_vm._v(" "+_vm._s(items[0].supplier_name)+" ("+_vm._s(items.length)+") ")]):(_vm.groupBy == 'category_id')?_c('td',{staticStyle:{"background-color":"#DCEDC8"},attrs:{"colspan":"13"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Categoria:")]),_vm._v(" "+_vm._s(items[0].category_name)+" ("+_vm._s(items.length)+") "),_c('span',{staticClass:"font-weight-bold ml-4"},[_vm._v("Presupuesto Usado ("+_vm._s(_vm.currency_sign)+"):")]),_vm._v(" "),_c('span',{class:'font-weight-bold ' + (_vm.budgetTotal[items[0].category_id] > items[0].budget ? 'red--text' : 'green--text')},[_vm._v(_vm._s(_vm.budgetTotal[items[0].category_id].toFixed(0)))]),_vm._v(" de "+_vm._s(items[0].budget)+" "),(_vm.enable_budget && _vm.budgetTotal[items[0].category_id] > items[0].budget)?_c('v-btn',{staticClass:"ml-6",attrs:{"small":"","color":"success","loading":_vm.loading},on:{"click":function($event){return _vm.requestBudget(items[0])}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("request_page")]),_vm._v("Solicitar Presupuesto Adicional")],1):_vm._e()],1):_c('td',{staticStyle:{"background-color":"#B2DFDB"},attrs:{"colspan":"13"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Marca:")]),_vm._v(" "+_vm._s(items[0].manufacturer_name)+" ("+_vm._s(items.length)+") ")])]}},{key:"item.cost_wt",fn:function(ref){
                            var item = ref.item;
return [(_vm.canEdit(item))?_c('v-text-field',{staticClass:"detail-quantity",staticStyle:{"max-width":"80px"},attrs:{"type":"number","dense":"","hide-details":""},on:{"input":function($event){return _vm.updateDetail(item)}},model:{value:(item.cost_wt),callback:function ($$v) {_vm.$set(item, "cost_wt", $$v)},expression:"item.cost_wt"}}):_c('span',[_vm._v(_vm._s(item.cost_wt))])]}},{key:"item.quantity",fn:function(ref){
                            var item = ref.item;
return [(_vm.canEdit(item))?_c('v-text-field',{staticClass:"detail-quantity",staticStyle:{"max-width":"80px"},attrs:{"type":"number","dense":"","hide-details":""},on:{"input":function($event){return _vm.updateDetail(item)},"change":function($event){return _vm.changeQuantity(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}):_c('span',[_vm._v(_vm._s(item.quantity)+" "+_vm._s(item.unity_sym))])]}},{key:"item.state_id",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{style:({backgroundColor: _vm.getState(item.state_id).extra}),attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.openDocument(item)}}},[_vm._v(_vm._s(_vm.getState(item.state_id).name))])]}},{key:"item.product_name",fn:function(ref){
                            var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openMvts(item)}}},[_vm._v(_vm._s(item.product_name))])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['alt', 'p']),expression:"['alt', 'p']"}],staticStyle:{"height":"100px","width":"100px"},attrs:{"icon":"","large":"","disabled":_vm.disabledAdd},on:{"click":_vm.createProduct,"shortkey":_vm.createProduct}},[_c('v-icon',{attrs:{"size":"100px","color":"success"}},[_vm._v("add_box")])],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Agrege sus productos!")])]),_c('v-col',{staticStyle:{"font-size":"16px"},attrs:{"cols":"12","lg":"12"}})],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('add-product',{attrs:{"showAddMessage":false,"entitydetail":"purchaseplanningproduct"},on:{"add-product":_vm.afterAddProduct},model:{value:(_vm.createModalShow),callback:function ($$v) {_vm.createModalShow=$$v},expression:"createModalShow"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }