import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Customer from '../../entities/customers/customer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CustomerChildState extends ListState<Customer>{
    editModel:Customer;
    activeList: Array<Customer>;
}
class CustomerChildModule extends ListModule<CustomerChildState,any,Customer>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Customer>(),
        loading:false,
        editModel: new Customer(),
        activeList: new Array<Customer>(),
        path: 'customers'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: CustomerChildState, model: Customer) {
            state.editModel = Object.assign({});
            state.editModel.code = '0';
            state.editModel.group_id = Util.abp.setting.get('DEFAULT_GROUP');
            state.editModel.document_type_id = Util.abp.setting.get('DEFAULT_DOCUMENT');
            state.editModel.person_type_id = Util.abp.list.get('T_PERSON', 'N').id;
            state.editModel.type_id = null;   
        },
        edit(state: CustomerChildState, model: Customer) {
            state.editModel = model;

            if (Util.abp.setting.get('ENABLE_CUSTOMER_LOGIN') == 1 && model['account'] != null) {
                state.editModel.username = model['account'].username;
            }
        },
    });
}
const customerchildModule = new CustomerChildModule();
export default customerchildModule;