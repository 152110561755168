import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Customer from '../../entities/customers/customer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import axios from 'axios'
import Address from '../../entities/customers/address'

interface CustomerState extends ListState<Customer>{
    editModel:Customer;
    activeList: Array<Customer>;
    reportList: Array<any>;
    loadingCL: boolean;
    filename: string;
}
class CustomerModule extends ListModule<CustomerState,any,Customer>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Customer>(),
        loading:false,
        loadingCL: false,
        editModel: new Customer(),
        activeList: new Array<Customer>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'customers'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            payload.data.addresses.forEach(item => {
                item.country_id = (item.country_id != null && typeof item.country_id == "object" ? item.country_id.id : item.country_id);
                if (item.ubigeo != null) {
                    item.department_id = item.ubigeo.department;
                    item.province_id = item.ubigeo.province;
                    item.district_id = item.ubigeo.district;
                }                
            });

            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async createFromSunat(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            
            let response = await Ajax.post('/api/' + (context.state as any).path + '/sunat', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async update(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            payload.data.addresses.forEach(item => {
                item.country_id = (item.country_id != null && typeof item.country_id == "object" ? item.country_id.id : item.country_id);
                if (item.ubigeo != null) {
                    item.department_id = item.ubigeo.department;
                    item.province_id = item.ubigeo.province;
                    item.district_id = item.ubigeo.district;
                }
            });

            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            let model = response.data.result;
            if (context.state.activeList != undefined) {
                context.state.activeList.forEach((item, k) => {
                    if (item.id == model.id)
                        context.state.activeList[k] = Util.extend(context.state.activeList[k], model);
                });
            }

            context.state.list.forEach((item, k) => {
                if (item.id == model.id)
                    context.state.list[k] = Util.extend(context.state.list[k], model);
            });

            return model;
        },
        async get(context: ActionContext<CustomerState, any>, payload: any) {
            if (payload.id == undefined) return {};
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as Customer;
            context.state.list.push(item);
            context.state.loading = false;
            return item;
        },
        async approve(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.put('/api/customers/approve/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async reject(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.put('/api/customers/reject/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async getDataByDocument(context: ActionContext<CustomerState, any>, payload: any) {
            let result = null;
            let url = '/api/customers/get-data-by-' + payload.data.type;
            await Ajax.get(url, { params: payload.data }).then(response => {
                result = response;
            }).catch(e => {
                result = null;
            });

            if (result && result.data.success)
                return result.data.result;
            else
                return false;
        },
        async getLastCode(context: ActionContext<CustomerState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/customers/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
        async getCreditLine(context: ActionContext<CustomerState, any>, payload: any) {
            let result = null;
            context.state.loadingCL = true;
            let response = await Ajax.get('/api/customers/credit-line', { params: payload.data }).catch(e => {
                context.state.loadingCL = false;
                return Promise.reject(e);
            });

            return response.data.result;
        },
        async updateBatch(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/customers/batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async importData(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/customers/import',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = response.data.result;
            return true;
        },
        async reportCustomerQuery(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/customer-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            if (payload.data.type == 'r')
                context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: CustomerState, model: Customer) {
            state.editModel = model;

            if (Util.abp.setting.get('ENABLE_CUSTOMER_LOGIN') == 1 && model['account'] != null) {
                state.editModel.username = model['account'].username;
            }            

            if (state.editModel.addresses.length < 3) {
                state.editModel.addresses.forEach(item => {
                    if (item.ubigeo == undefined) item.ubigeo = {};

                    item.ubigeo.department = item.department_id;
                    item.ubigeo.province = item.province_id;
                    item.ubigeo.district = item.district_id;
                });

                let newsAddrs = state.editModel.addresses.filter((addr: any) => {
                    return addr.other === 'Nueva';
                });

                if (newsAddrs.length == 0) {
                    let addr = new Address();
                    addr.id = 0;
                    addr.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
                    addr.ubigeo = {
                        department: 4,
                        province: 35,
                        district: 330
                    };
                    addr.department_id = 4;
                    addr.province_id = 35;
                    addr.district_id = 330;
                    addr.other = 'Nueva';
                    state.editModel.addresses.push(addr);
                }
            }
        },
        new(state: CustomerState, model: Customer) {
            state.editModel = Object.assign({});
            state.editModel.addresses = [];
            state.editModel.code = '0';
            state.editModel.group_id = Util.abp.setting.get('DEFAULT_GROUP');
            state.editModel.document_type_id = Util.abp.setting.get('DEFAULT_DOCUMENT');
            state.editModel.person_type_id = Util.abp.setting.get('DEFAULT_PERSON_TYPE');
            state.editModel.type_id = null;
            
            if (Util.abp.list.get('T_ORIGIN', 'N') != undefined)
                state.editModel.type_id = Util.abp.list.get('T_ORIGIN','N').id;
             
            let addr = new Address();
            addr.id = 0;
            addr.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            addr.address1 = '';
            addr.ubigeo = {
                department: 4,
                province: 35,
                district: 330
            };
            addr.department_id = 4;
            addr.province_id = 35;
            addr.district_id = 330;
            addr.other = 'Nueva';
            state.editModel.addresses.push(addr);
        },
        setAddresses(state: CustomerState, list: Array<Address>) {
            if (list != null) {
                state.editModel.addresses = list;
            }
        },
        setReportList(state: CustomerState, list: Array<any>) {
            state.reportList = list;
        },
    });
}
const customerModule=new CustomerModule();
export default customerModule;