import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Sale from '../../entities/sales/sale'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import appconst from '../../../lib/appconst'
import PageResult from '@/store/entities/page-result';
import SaleDetail from '@/store/entities/sales/saledetail';
import SaleItem from '@/store/entities/sales/saleitem';
import SaleHistory from '@/store/entities/sales/history';
import Address from '@/store/entities/customers/address';
import Quotation from '@/store/entities/sales/quotation';
import SaleOrder from '@/store/entities/sales/saleorder';
import FoodOrder from '@/store/entities/foods/foodorder';
import Currency from '@/store/entities/preferences/currency';
import DeliveryOrder from '@/store/entities/stocks/deliveryorder';
import ServiceOrder from '@/store/entities/production/serviceorder';
import axios from 'axios'
import RepairOrder from '@/store/entities/workshops/repairorder';
import Booking from '@/store/entities/bookings/booking';
import SaleDiscount from '@/store/entities/sales/salediscount';

interface SaleState extends ListState<Sale>{
    editModel:Sale;       
    hisLoading: boolean;
    hasChanges: boolean;        
    history: Array<SaleHistory>;   
    addresses: Array<Address>;
    saleDiscounts: Array<SaleDiscount>;
    reportList: Array<any>;
    monthly: Array<any>;
    filename: string;
    discounts: number;
}
class SaleModule extends ListModule<SaleState,any,Sale>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Sale>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new Sale(),
        activeList: new Array<Sale>(),
        addresses: new Array<Address>(),
        history: new Array<SaleHistory>(),
        saleDiscounts: new Array<SaleDiscount>(),
        reportList: new Array<any>(),
        monthly: new Array<any>(),
        filename: '',
        discounts: 0,
        path: 'sales'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<SaleState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Sale>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            context.state.list.forEach(item => {
                if (item.invoice_type.invoiceable && item.invoice_type.code == 'BV' && item.valid && !item.invoiced && item.current_state_id != appconst.sState.PROC) {
                    item.current_state.name = 'Por Procesar / ' + item.current_state.name;
                    item.current_state.color = '#039BE5FF';
                } else if (item.invoice_type.invoiceable && item.invoice_type.code == 'F' && item.valid && item.invoiced &&
                    (item.current_state_id == appconst.sState.TDEL || item.current_state_id == appconst.sState.DISP)) {
                    item.current_state.name = 'Aceptada / ' + item.current_state.name;
                } else if (!item.invoice_type.invoiceable && item.valid && item.current_state_id == appconst.sState.PEND) {
                    item.current_state.name = 'Confirmado';
                    item.current_state.color = '#039BE5FF';
                }
            });

            return context.state.list;
        },
        async create(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async cancel(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/sales/cancel', { id: payload.data.id, cancel_reason: payload.data.cancel_reason } ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
        },
        async invoice(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true; 
            let response = await Ajax.post('/api/sale-invoices', payload.data).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            if (!response)
                return response;
            else if ((response as any).data != undefined) 
                return (response as any).data.result;
            return false;
        },
        async delivery(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/sales/delivery', payload.data).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return true;
        },
        async getHistory(context: ActionContext<SaleState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/sales/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;

            let format = Util.abp.setting.get('SALES_PRINT_FORMAT');
            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');
            
            let api = (format == 'A4' ? 'sale-invoice' :'sale-invoice-receipt');

            let response = await Ajax.get('/api/' + api, { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1 && format == 'TK') {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;                
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/' + api) + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                }
                return '';
            } else 
                return response.data.result;            
        },
        async getPayableDocuments(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/sales/payable-documents', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Sale>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async hasDeliveryOrder(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/sales/has-delivery-order', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
        async getSalesForCreditNotes(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/sales/for-credit-notes', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Sale>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async getSalesForReferralGuides(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/sales/for-referral-guides', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Sale>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async getMonthlySales(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.monthly = [];
            let reponse = await Ajax.get('/api/sales/monthly', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.monthly = reponse.data.result;
            context.state.loading = false;
        },

        async reportSalesSellers(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-sellers', {params: payload.data}).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e); 
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesQuery(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesProducts(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesDiscounts(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-discounts', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesCustomers(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-customers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesCanceled(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-canceled', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSunatFormat14(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sunat-format14', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportEntrances(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-entrances', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportEntrancePayments(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-entrance-payment', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesProductBase(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-product-base', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportTopProducts(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/top-products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportTopCustomers(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/top-customers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: SaleState, model: Sale) { 
            state.addresses = [];
            let allowDispatch = Util.abp.setting.get('ALLOW_SALE_DISPATCH');

            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY_SALE');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            currency.conversion_rate = 1;
            state.editModel = Object.assign({});

            state.editModel.customer = Object.assign({});
            state.editModel.customer.type_id = Util.abp.list.get('T_ORIGIN', 'N').id;
            state.editModel.customer.id = Util.abp.setting.get('DEFAULT_CUSTOMER');
            state.editModel.customer_id = state.editModel.customer.id;
            state.editModel.customer_code = '';
            state.editModel.customer_name = Util.abp.setting.get('DEFAULT_CUSTOMER_NAME');
            state.editModel.customer_document = Util.abp.setting.get('DEFAULT_CUSTOMER_DOCUMENT');
            state.editModel.group_name = '';
            state.editModel.credit_line = 0;
            state.editModel.credit_days = 0;

            state.editModel.delivery_address = null;
            state.editModel.delivery_address_id = null;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = Util.abp.clock.today();
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_CUSTOMER');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY_SALE');
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.tax_rate = Util.abp.setting.get('TAX_RATE');
            state.editModel.currency = currency;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_type = { id: state.editModel.invoice_type_id, name: 'Comprobante'};
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.payment_type_id = Util.abp.list.get('C_SALEPAID', 'CO').id;
            if (allowDispatch == 1) {
                state.editModel.dispatch_type_id = Util.abp.list.get('T_DISPATCH', 'EI').id;
                state.editModel.dispatch_date = Util.abp.clock.today();
            }
            state.editModel.exchange_rate = 1;
            state.editModel.discount_type = 'amount';
            state.editModel.discount_mode = Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE', 'S');
            state.editModel.discounts = 0.00;
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.tax_igv = 0.00;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.invoice_number = '0';
            state.editModel.invoiced = false;
            state.editModel.invoice_serie = '';
            state.editModel.detraction = false;
            state.editModel.retention = false;
            state.editModel.perception = false;
            state.editModel.charges = [];
            state.editModel.charge_detraction_id = null;
            state.editModel.charge_retention_id = null;
            state.editModel.charge_perception_id = null;
            state.editModel.details = new Array<SaleDetail>();
            state.editModel.items = new Array<SaleItem>();
            state.hasChanges = false;
            state.discounts = 0;
            state.saleDiscounts = [];

            if (Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE') == 'M') {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            state.editModel.seller_id = Util.abp.session.userId;
        },
        edit(state: SaleState, model: Sale) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.invoice_type_name = model.invoice_type.description;
            state.editModel.credit_line = model.customer.credit_line;
            state.saleDiscounts = model.sale_discounts;
            if (model.customer.group != null) state.editModel.group_name = model.customer.group.name;

            state.hasChanges = false;
            state.discounts = model.discounts;

            if (state.saleDiscounts == null) {
                state.saleDiscounts = [];
            }            

            if (state.editModel.discount_mode == 'M' && state.saleDiscounts.length == 0) {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            if (Util.abp.setting.get('ALLOW_DETRACTION') == 1 && state.editModel.charges != undefined) {
                if (state.editModel.detraction) {
                    let typeDE = Util.abp.list.get('T_CHARGES','DE').id;
                    state.editModel.charges.forEach(item => {
                        if (item.invoice_charge.charge_type_id == typeDE)
                            state.editModel.charge_detraction_id = item.invoice_charge_id;
                    });                    
                }
            } 

            if (Util.abp.setting.get('ALLOW_RETENTION') == 1 && state.editModel.charges != undefined) {
                if (state.editModel.retention) {
                    let typeRE = Util.abp.list.get('T_CHARGES', 'RE').id;
                    state.editModel.charges.forEach(item => {
                        if (item.invoice_charge.charge_type_id == typeRE)
                            state.editModel.charge_retention_id = item.invoice_charge_id;
                    });
                }
            }

            if (Util.abp.setting.get('ALLOW_PERCEPTION') == 1 && state.editModel.charges != undefined) {
                if (state.editModel.perception) {
                    let typePE = Util.abp.list.get('T_CHARGES', 'PE').id;
                    state.editModel.charges.forEach(item => {
                        if (item.invoice_charge.charge_type_id == typePE)
                            state.editModel.charge_perception_id = item.invoice_charge_id;
                    });
                }
            }
        },
        setReportList(state: SaleState, list: Array<any>) {
            state.reportList = list;
            state.totalCount = list.length;
        },
        setCurrency(state: SaleState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.hasChanges = true;
        },
        setCustomer(state: SaleState, customer: any) {
            state.editModel.customer = customer;
            state.editModel.customer_id = customer.id;
            state.hasChanges = true;
        },
        updatePayments(state: SaleState, total_paid: any) {
            state.editModel.total_paid = parseFloat(total_paid);
            state.editModel.balance = parseFloat((state.editModel.total_wt - state.editModel.total_paid).toFixed(2));
            if (state.editModel.total_paid > 0)
                state.editModel.current_state_id = appconst.sState.PART;

            if (state.editModel.total_paid == state.editModel.total_wt)
                state.editModel.current_state_id = appconst.sState.PAID;
            state.hasChanges = true;
        },
        setAddresses(state: SaleState, addresses: any) {
            state.addresses = addresses;
        },
        setInvoice(state: SaleState, data: any) {
            state.editModel.invoice_type_name = data.invoice_type.description;
            state.editModel.invoice_type_id = data.invoice_type.id;
            if (data.invoice_type.series[0] != undefined)
                state.editModel.invoice_serie = data.invoice_type.series[0].serie;
            state.editModel.invoice_number = data.number;
            state.hasChanges = true;
        },
        setTotals(state: SaleState, data: any) {
            if (state.editModel.total_wt != data.total_wt) state.hasChanges = true;
            state.editModel.subtotal = data.subtotal;
            state.editModel.subtotal_wt = data.subtotal_wt;
            state.editModel.discounts = data.discount_total;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.total_paid = data.total_paid;
            state.editModel.total_return = data.total_return;
            state.editModel.balance = state.editModel.total_wt - (state.editModel.total_paid + state.editModel.total_return);
            state.discounts = data.discount_total;
            if (Math.abs(state.editModel.balance) < 0.01) state.editModel.balance = 0;
        },
        addCharge(state: SaleState, model: any) {
            if (state.editModel.charges == null) state.editModel.charges = [];
            state.editModel.charges.push(model);
            state.hasChanges = true;
        },
        updateCharge(state: SaleState, model: any) {
            let index = null;
            state.editModel.charges.forEach((a,k) => {
                if (a.invoice_charge_id == model.invoice_charge_id) {
                    index = k;
                }
            });
            state.editModel.charges[index].invoice_charge_id = model.invoice_charge_id;
            state.editModel.charges[index].rate = model.rate;
            state.editModel.charges[index].payment_code = model.payment_code;
            state.editModel.charges[index].bank_account = model.bank_account;

            state.hasChanges = true;
        },
        removeCharge(state: SaleState, model: any) {
            let index = null;
            state.editModel.charges.forEach((a, k) => {
                if (a.invoice_charge_id == model.invoice_charge_id) {
                    index = k;
                }
            });
            state.editModel.charges.splice(index, 1);
            state.hasChanges = true;
        },
        addDiscount(state: SaleState) {
            if (state.editModel.sale_discounts == null) state.editModel.sale_discounts = [];
            let dis = new SaleDiscount();
            dis.discounts = 0;
            dis.discount_type = 'amount';
            dis.name = 'Descuento';
            state.saleDiscounts.push(dis);
            state.hasChanges = true;
        },
        removeDiscount(state: SaleState, index: any) {
            state.saleDiscounts.splice(index, 1);
            state.hasChanges = true;
        },
        loadFromQuotation(state: SaleState, model: Quotation) {
            if (model == undefined) return;
            let allowDispatch = Util.abp.setting.get('ALLOW_SALE_DISPATCH');
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            //state.addresses = [];

            state.editModel = Object.assign({});
            state.editModel.id = null;
            state.editModel.branch_id = model.branch_id;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.group_name = model.group_name;
            state.editModel.credit_line = model.customer.credit_line;
            state.editModel.reference = model.reference;
            state.editModel.quotation_nro = model.nro;
            state.editModel.sale_quotation_id = model.id;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.payment_type_id = model.payment_type_id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency_id = model.currency_id;
            state.editModel.seller_id = model.seller_id;
            state.editModel.credit_days = model.credit_days;
            state.editModel.including_taxes = model.including_taxes;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_number = '0';
            state.editModel.detraction = false;
            state.editModel.retention = false;
            state.editModel.perception = false;
            state.editModel.charge_detraction_id = null;
            state.editModel.charge_retention_id = null;
            state.editModel.charge_perception_id = null;
            if (allowDispatch == 1) {
                state.editModel.dispatch_type_id = Util.abp.list.get('T_DISPATCH', 'EI').id;
                state.editModel.dispatch_date = Util.abp.clock.today();
            }
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.discount_type = model.discount_type;
            state.editModel.discount_mode = Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE', 'S');
            state.editModel.discounts = model.discounts;
            state.editModel.subtotal = model.subtotal;
            state.editModel.subtotal_wt = model.subtotal_wt;
            state.editModel.tax_igv = model.taxes;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = model.taxes;
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.total_paid = 0;
            state.editModel.balance = model.total_wt;
            state.editModel.details = new Array<SaleDetail>();
            state.hasChanges = false;
            state.discounts = model.discounts;
            state.editModel.charges = [];
            state.saleDiscounts = [];

            if (Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE') == 'M') {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            model.details.forEach((item, index) => {
                let det = new SaleDetail();
                det = Util.extend(true, {}, item);
                det.warehouse_id = Util.abp.session.warehouseId;
                delete det.id;
                state.editModel.details.push(det);
            });
        },
        loadFromSaleOrder(state: SaleState, model: SaleOrder) {
            if (model == undefined) return;
            let allowDispatch = Util.abp.setting.get('ALLOW_SALE_DISPATCH');
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.addresses = [model.delivery_address];

            state.editModel = Object.assign({});
            state.editModel.id = null;
            state.editModel.branch_id = model.branch_id;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.group_name = model.group_name;
            state.editModel.credit_line = model.customer.credit_line;
            state.editModel.reference = model.reference;
            state.editModel.quotation_nro = model.quotation_nro;
            state.editModel.order_nro = model.nro;
            state.editModel.sale_quotation_id = model.sale_quotation_id;
            state.editModel.sale_order_id = model.id;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.payment_type_id = model.payment_type_id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency = model.currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.seller_id = model.seller_id;
            state.editModel.credit_days = model.credit_days;
            state.editModel.including_taxes = model.including_taxes;
            state.editModel.delivery_address = model.delivery_address;
            state.editModel.delivery_address_id = model.delivery_address_id;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_number = '0';
            state.editModel.detraction = false;
            state.editModel.retention = false;
            state.editModel.perception = false;
            state.editModel.charge_detraction_id = null;
            state.editModel.charge_retention_id = null;
            state.editModel.charge_perception_id = null;
            if (allowDispatch == 1) {
                state.editModel.dispatch_type_id = Util.abp.list.get('T_DISPATCH', 'EI').id;
                state.editModel.dispatch_date = Util.abp.clock.today();
            }
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.discount_type = model.discount_type;
            state.editModel.discount_mode = Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE', 'S');
            state.editModel.discounts = model.discounts;
            state.editModel.subtotal = model.subtotal;
            state.editModel.subtotal_wt = model.subtotal_wt;
            state.editModel.tax_igv = model.taxes;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = model.taxes;
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.total_paid = model.total_paid;
            state.editModel.balance = model.total_wt - model.total_paid;
            state.editModel.notes = model.notes;
            state.editModel.details = new Array<SaleDetail>();
            state.hasChanges = false;
            state.discounts = model.discounts;
            state.editModel.charges = [];
            state.saleDiscounts = [];
            if (model['payments'] != undefined)
                state.editModel.payments = model['payments'];
            
            if (Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE') == 'M') {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            model.details.forEach((item, index) => {
                let det = new SaleDetail();
                det = Util.extend(true, {}, item);
                delete det.id;
                if (item.product_location_id != null && item.product_location_id > 0)
                    det['location'] = { id: item.product_location_id };

                if (item.batches != null && det.batches.length > 0) {
                    let loc = det.batches[0];
                    if (loc != null) {
                        det.location = {
                            id: loc.product_location_id,
                            stock: loc.stock,
                        };
                        det.batches = [];
                        item.batches.forEach(b => {
                            det.batches.push({
                                batch_id: b.batch_id,
                                product_location_id: b.product_location_id,
                                quantity: det.quantity,
                                stock: det.stock,
                                warehouse_id: det.warehouse_id,
                                product_location: b.product_location,
                            });
                        });
                    }
                }

                state.editModel.details.push(det);
            });
        },
        loadFromFoodOrder(state: SaleState, model: FoodOrder) {
            if (model == undefined) return;
            let allowDispatch = Util.abp.setting.get('ALLOW_SALE_DISPATCH');
            let customer = model[Util.abp.setting.get('FOOD_ORDER_SALE_BY_CUSTOMER', 'customer')];
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.addresses = [];

            state.editModel = Object.assign({});
            state.editModel.id = null;
            state.editModel.branch_id = model.branch_id;
            state.editModel.customer = customer;
            state.editModel.customer_code = customer.code;
            state.editModel.customer_name = customer.fullname;
            state.editModel.customer_document = customer.document_full;
            state.editModel.credit_line = customer.credit_line;
            state.editModel.credit_days = 0;
            state.editModel.reference = model.reference;
            state.editModel.order_nro = model.number;
            state.editModel.food_order_id = model.id;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.payment_type_id = Util.abp.list.get('C_SALEPAID', (model.total_paid >= model.total_wt ? 'CO' : 'CR')).id;
            state.editModel.customer_id = customer.id;
            state.editModel.currency = model.currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.seller_id = Util.abp.session.userId;
            state.editModel.including_taxes = true;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_number = '0';
            state.editModel.detraction = false;
            state.editModel.retention = false;
            state.editModel.perception = false;
            state.editModel.charge_detraction_id = null;
            state.editModel.charge_retention_id = null;
            state.editModel.charge_perception_id = null;
            if (allowDispatch == 1) {
                state.editModel.dispatch_type_id = Util.abp.list.get('T_DISPATCH', 'EI').id;
                state.editModel.dispatch_date = Util.abp.clock.today();
            }
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.discount_type = 'amount';
            state.editModel.discount_mode = Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE', 'S');
            state.editModel.discounts = 0;
            state.editModel.subtotal = model.subtotal;
            state.editModel.subtotal_wt = model.subtotal_wt;
            state.editModel.tax_igv = model.taxes;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = model.taxes;
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.total_paid = model.total_paid;
            state.editModel.balance = model.total_wt - model.total_paid;
            state.editModel.notes = model.comment;
            state.editModel.charges = [];
            state.editModel.details = new Array<SaleDetail>();
            state.hasChanges = false;
            state.discounts = 0;
            state.saleDiscounts = [];
            if (model['payments'] != undefined)
                state.editModel.payments = model['payments'];

            if (Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE') == 'M') {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            let total = model.total / model.products.length;
            let total_wt = model.total_wt / model.products.length;

            model.products.forEach((item, index) => {
                let exist = state.editModel.details.filter((detail: any) => {
                    return detail.product_id == item.product_id && detail.product_attribute_id == item.product_attribute_id;
                });

                if (exist.length > 0) {
                    exist[0].quantity += item.quantity;
                    exist[0].total = exist[0].quantity * exist[0].price;
                    exist[0].total_wt = exist[0].quantity * exist[0].price_wt
                    exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(6));
                } else {
                    let det = new SaleDetail(); det = Util.extend(true, {}, item);
                    det.stock_physical = item.stock;
                    det.total = total;
                    det.total_wt = total_wt;
                    det.price = det.total / det.quantity;
                    det.price_wt = det.total_wt / det.quantity;
                    det.taxes = parseFloat((det.total_wt - det.total).toFixed(6));
                    det.service = true;
                    delete det.id;
                    state.editModel.details.push(det);
                }
            });
        },
        loadFromDelivery(state: SaleState, model: DeliveryOrder) {
            if (model == undefined) return;
            let allowDispatch = Util.abp.setting.get('ALLOW_SALE_DISPATCH');
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.editModel.branch_id = model.branch_id;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.group_name = '';
            state.editModel.credit_line = model.customer.credit_line;
            state.editModel.credit_days = 0;
            state.addresses = model.customer.addresses;
            if (state.addresses != null && state.addresses.length > 0)
                state.editModel['delivery_address'] = state.addresses[0];

            let tax_rate = Util.abp.setting.get('TAX_RATE');
            state.editModel = Object.assign({});
            state.editModel.id = null;
            state.editModel.reference = model.reference;
            state.editModel.delivery_order_id = model.id;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency = model.currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.including_taxes = true;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_number = '0';
            state.editModel.detraction = false;
            state.editModel.retention = false;
            state.editModel.perception = false;
            state.editModel.charge_detraction_id = null;
            state.editModel.charge_retention_id = null;
            state.editModel.charge_perception_id = null;
            if (allowDispatch == 1) {
                state.editModel.dispatch_type_id = Util.abp.list.get('T_DISPATCH', 'EI').id;
                state.editModel.dispatch_date = Util.abp.clock.today();
            }
            state.editModel.currency_sign = model.currency_sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.tax_rate = tax_rate;
            state.editModel.discount_type = 'amount';
            state.editModel.discount_mode = Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE', 'S');
            state.editModel.discounts = 0;
            state.editModel.subtotal = Util.removeTaxes(model.total_wt, tax_rate);
            state.editModel.subtotal_wt = model.total_wt;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.total = Util.removeTaxes(model.total_wt, tax_rate);
            state.editModel.total_wt = model.total_wt;
            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
            state.editModel.tax_igv = state.editModel.total_wt - state.editModel.total;
            state.editModel.total_paid = model.total_paid;
            state.editModel.balance = model.total_wt - model.total_paid;
            state.editModel.details = new Array<SaleDetail>();
            state.editModel.charges = [];
            state.hasChanges = false;
            state.discounts = 0;
            state.saleDiscounts = [];
            if (Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE') == 'M') {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            state.editModel.seller_id = Util.abp.session.userId;

            model.details.forEach((item, index) => {
                let det = new SaleDetail();
                det = Util.extend(true, {}, item);
                delete det.id;

                det.price = Util.removeTaxes(item.price_wt, tax_rate);
                det.total = Util.removeTaxes(item.total_wt, tax_rate);
                det.taxes = det.total_wt - det.total;
                det.product_price = item.product_price;
                det.product_cost = 0;
                det.price_rule_id = Util.abp.setting.get('DEFAULT_PRICE_RULE');
                det.tax_id = Util.abp.setting.get('IGV_TAXES');
                det.tax_rate = tax_rate;
                det.discounts = 0;
                det['stock_physical'] = det.stock;

                if (item.batches != null && det.batches.length > 0) {
                    let loc = det.batches[0];
                    if (loc != null) {
                        det.location = {
                            id: loc.product_location_id,
                            stock: loc.stock,
                        };
                        det.batches = [];
                        item.batches.forEach(b => {
                            det.batches.push({
                                batch_id: b.batch_id,
                                product_location_id: b.product_location_id,
                                quantity: det.quantity,
                                stock: det.stock,
                                warehouse_id: det.warehouse_id,
                                product_location: b.product_location,
                            });
                        });
                    }
                }

                state.editModel.details.push(det);
            });
        },
        loadFromServiceOrder(state: SaleState, model: ServiceOrder) {
            if (model == undefined) return;
            let allowDispatch = Util.abp.setting.get('ALLOW_SALE_DISPATCH');
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.addresses = [];

            state.editModel = Object.assign({});
            state.editModel.id = null;
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.group_name = '';
            state.editModel.credit_line = model.customer.credit_line;
            state.editModel.credit_days = 0;
            state.editModel.reference = model.reference;
            state.editModel.sale_quotation_id = model.sale_quotation_id;
            state.editModel.service_order_id = model.id;
            state.editModel.quotation_nro = model.quotation_nro;
            state.editModel.origin_nro = model.nro;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.payment_type_id = model.payment_type_id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency = model.currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.including_taxes = model.including_taxes;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_number = '0';
            state.editModel.detraction = false;
            state.editModel.retention = false;
            state.editModel.perception = false;
            state.editModel.charge_detraction_id = null;
            state.editModel.charge_retention_id = null;
            state.editModel.charge_perception_id = null;
            if (allowDispatch == 1) {
                state.editModel.dispatch_type_id = Util.abp.list.get('T_DISPATCH', 'EI').id;
                state.editModel.dispatch_date = Util.abp.clock.today();
            }
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.discount_type = 'amount';
            state.editModel.discount_mode = Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE', 'S');
            state.editModel.discounts = 0;
            state.editModel.subtotal = model.total;
            state.editModel.subtotal_wt = model.total_wt;
            state.editModel.tax_igv = model.taxes;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = model.taxes;
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.total_paid = 0;
            state.editModel.balance = model.total_wt;
            state.editModel.details = new Array<SaleDetail>();
            state.editModel.charges = [];
            state.hasChanges = false;
            state.discounts = 0;
            state.saleDiscounts = [];
            if (Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE') == 'M') {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            if (Util.abp.session.isSeller())
                state.editModel.seller_id = Util.abp.session.userId;

            model.details.forEach((item, index) => {
                let det = new SaleDetail();
                det = Util.extend(true, {}, item);
                det.product_id = item.service_id;
                det.product_attribute_id = 0;
                //det.product_attribute_id = item['product_attribute_id'];
                det.warehouse_id = Util.abp.session.warehouseId;
                det.product_name = item.service_name;
                det.stock_physical = 0;
                det.stock_reserved = 0;
                det.stock = 0;
                det.price = item.cost;
                det.price_wt = item.cost_wt;
                det.discount_type = 'percent';
                det.discounts = 0;
                det.product_price = 0;
                det.exchange_rate = model.exchange_rate;
                
                delete det.id;
                state.editModel.details.push(det);
            });
        },
        loadFromWorkOrder(state: SaleState, model: any) {
            if (model == undefined) return;
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.addresses = [];

            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            currency.conversion_rate = 1;

            state.editModel = Object.assign({});
            state.editModel.id = null;
            state.editModel.branch_id = model.branch_id;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = (model.customer.document.length == 11 ? 'RUC' : 'DNI') + ' - ' + model.customer.document;
            state.editModel.group_name = '';
            state.editModel.credit_line = model.customer.credit_line;
            state.editModel.credit_days = 0;
            state.editModel.reference = model.reference;
            state.editModel.sale_quotation_id = model.sale_quotation_id;
            state.editModel.work_order_id = model.id;
            state.editModel.quotation_nro = model.quotation_nro;
            state.editModel.origin_nro = model.nro;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.payment_type_id = Util.abp.list.get('C_SALEPAID', 'CO').id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency = currency;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.including_taxes = true;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_number = '0';
            state.editModel.detraction = false;
            state.editModel.retention = false;
            state.editModel.perception = false;
            state.editModel.charge_detraction_id = null;
            state.editModel.charge_retention_id = null;
            state.editModel.charge_perception_id = null;
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.exchange_rate = currency.conversion_rate;
            state.editModel.discount_type = 'amount';
            state.editModel.discount_mode = Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE', 'S');
            state.editModel.discounts = 0;
            state.editModel.subtotal_wt = model.total_wt;
            state.editModel.subtotal = Util.removeTaxes(state.editModel.subtotal_wt, Util.abp.setting.get('TAX_RATE'));
            state.editModel.tax_igv = parseFloat((state.editModel.subtotal_wt - state.editModel.subtotal).toFixed(6));
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = state.editModel.tax_igv;
            state.editModel.total = state.editModel.subtotal;
            state.editModel.total_wt = state.editModel.subtotal_wt;
            state.editModel.total_paid = 0;
            state.editModel.balance = state.editModel.total_wt;
            state.editModel.details = new Array<SaleDetail>();
            state.editModel.charges = [];
            state.hasChanges = false;
            state.discounts = 0;
            state.saleDiscounts = [];
            if (Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE') == 'M') {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            if (Util.abp.session.isSeller())
                state.editModel.seller_id = Util.abp.session.userId;

            let det = new SaleDetail();
            det.product_id = model.work_product_id;
            //det.product_attribute_id = model['product'].product_attribute_id;
            det.product_attribute_id = 0;
            det.warehouse_id = Util.abp.session.warehouseId;
            det.unity_id = model['product'].unity_id;
            det.unity_sym = model['product'].unity_symbol;
            det.tax_id = model['product'].tax_id;
            det.tax_rate = (model['product'].tax_rate != null ? model['product'].tax_rate : (model['product'].tax != null ? model['product'].tax.rate : Util.abp.setting.get('TAX_RATE')));
            det.code = model['product'].code;
            det.product_name = model['product'].name;
            det.quantity = model.quantity_production;
            det.stock_physical = model['product'].stock_physical;
            det.stock = model['product'].stock;
            det.stock_reserved = det.stock_physical - det.stock;
            det.price_wt = model.price_wt;
            det.price = Util.removeTaxes(det.price_wt, Util.abp.setting.get('TAX_RATE'));
            det.discount_type = 'percent';
            det.discounts = 0;
            det.total = det.price * det.quantity;
            det.total_wt = det.price_wt * det.quantity;
            det.product_price = model['product'].price_wt;
            det.exchange_rate = state.editModel.exchange_rate;
            det.product = model['product'];

            if (model['product'].prices != null && model['product'].prices.length > 0)
                det.price_rule_id = model['product'].prices[0].price_rule_id;
            
            state.editModel.details.push(det);
        },
        loadFromRepairOrder(state: SaleState, model: RepairOrder) {
            if (model == undefined) return;
            let allowDispatch = Util.abp.setting.get('ALLOW_SALE_DISPATCH');
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.addresses = [];

            let tax_rate = Util.abp.setting.get('TAX_RATE');
            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            currency.conversion_rate = 1;

            state.editModel = Object.assign({});
            state.editModel.id = null;
            state.editModel.branch_id = model.branch_id;
            if (model.customer != null) {
                state.editModel.customer = model.customer;
                state.editModel.customer_code = model.customer.code;
                state.editModel.customer_name = model.customer.fullname;
                state.editModel.customer_document = model.customer.document_full;
                state.editModel.credit_line = model.customer.credit_line;
            }
            state.editModel.group_name = '';
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.tax_rate = tax_rate;
            state.editModel.reference = model.reference;
            state.editModel.quotation_nro = model.quotation_nro;
            state.editModel.sale_quotation_id = model.sale_quotation_id;
            state.editModel.repair_order_id = model.id;
            state.editModel.origin_nro = model.nro;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.payment_type_id = Util.abp.list.get('C_SALEPAID', 'CO').id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency = currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.seller_id = Util.abp.session.userId;
            state.editModel.credit_days = 0;
            state.editModel.including_taxes = true;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_number = '0';
            state.editModel.detraction = false;
            state.editModel.retention = false;
            state.editModel.perception = false;
            state.editModel.charge_detraction_id = null;
            state.editModel.charge_retention_id = null;
            state.editModel.charge_perception_id = null;
            if (allowDispatch == 1) {
                state.editModel.dispatch_type_id = Util.abp.list.get('T_DISPATCH', 'EI').id;
                state.editModel.dispatch_date = Util.abp.clock.today();
            }
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.editModel.discount_type = 'amount';
            state.editModel.discount_mode = Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE', 'S');
            state.editModel.discounts = 0;
            state.editModel.subtotal = Util.removeTaxes(model.total);
            state.editModel.subtotal_wt = model.total;
            state.editModel.tax_igv = state.editModel.subtotal_wt - state.editModel.subtotal;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = state.editModel.tax_igv;
            state.editModel.total = state.editModel.subtotal;
            state.editModel.total_wt = state.editModel.subtotal_wt;
            state.editModel.total_paid = model.total_paid;
            state.editModel.balance = model.total - model.total_paid;
            state.editModel.notes = model.notes;
            state.editModel.details = new Array<SaleDetail>();
            state.editModel.charges = [];
            state.saleDiscounts = [];
            state.hasChanges = false;
            state.discounts = 0;
            if (model['payments'] != undefined)
                state.editModel.payments = model['payments'];
            if (Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE') == 'M') {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            model.products.forEach((item, index) => {
                let det = new SaleDetail();
                det = Util.extend(true, {}, item);

                det.discount_manual = true;
                det.tax_id = state.editModel.tax_id;
                det.tax_rate = state.editModel.tax_rate;
                det.exchange_rate = state.editModel.exchange_rate
                det.price = Util.removeTaxes(det.price_wt);
                det.total = Util.removeTaxes(det.total_wt);
                
                delete det.id;

                state.editModel.details.push(det);
            });
        },
        loadFromBooking(state: SaleState, model: Booking) {
            if (model == undefined) return;
            let allowDispatch = Util.abp.setting.get('ALLOW_SALE_DISPATCH');
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.addresses = [];

            state.editModel = Object.assign({});
            state.editModel.id = null;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.reference = model.reference;
            state.editModel.booking_id = model.id;
            state.editModel.origin_nro = model.nro;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.payment_type_id = Util.abp.list.get('C_SALEPAID', 'CO').id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency = model.currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.seller_id = Util.abp.session.userId;
            state.editModel.credit_days = 0;
            state.editModel.including_taxes = true;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_serie = '';
            state.editModel.invoice_number = '0';
            state.editModel.detraction = false;
            state.editModel.retention = false;
            state.editModel.perception = false;
            state.editModel.charge_detraction_id = null;
            state.editModel.charge_retention_id = null;
            state.editModel.charge_perception_id = null;
            if (allowDispatch == 1) {
                state.editModel.dispatch_type_id = Util.abp.list.get('T_DISPATCH', 'EI').id;
                state.editModel.dispatch_date = Util.abp.clock.today();
            }
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.discount_type = model.discount_type;
            state.editModel.discount_mode = Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE', 'S');
            state.editModel.discounts = model.discounts;
            state.editModel.subtotal = model.total;
            state.editModel.subtotal_wt = model.total_wt;
            state.editModel.tax_igv = model.taxes;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = model.taxes;
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.total_paid = model.total_paid;
            state.editModel.balance = model.total_wt - model.total_paid;
            state.editModel.details = new Array<SaleDetail>();
            state.editModel.charges = [];
            state.saleDiscounts = [];
            state.hasChanges = false;
            if (model['payments'] != undefined)
                state.editModel.payments = model['payments'];
            if (Util.abp.setting.get('GLOBAL_DISCOUNTS_MODE') == 'M') {
                let dis = new SaleDiscount();
                dis.discounts = 0;
                dis.discount_type = 'amount';
                dis.name = 'Descuento';
                state.saleDiscounts.push(dis);
            }

            model.products.forEach((item, index) => {
                let det = new SaleDetail();
                det = Util.extend(true, {}, item);
                det.warehouse_id = Util.abp.session.warehouseId;
                det.unity_id = Util.abp.setting.get('DEFAULT_UNITY');
                det.tax_id = model.tax_id;
                det.tax_rate = model.tax_rate;
                det.stock_physical = 0;
                det.stock_reserved = 0;
                det.stock = 0;
                det.discount_type = 'percent';
                det.discounts = 0;
                det.quantity = item.nro_pax * item.days;
                det.total_wt = Util.convertAmountToCurrency(item.total_wt, item.currency_id, model.currency_id)
                det.total = Util.removeTaxes(det.total_wt, det.tax_rate);
                det.price_wt = det.total_wt / det.quantity;
                det.price = det.total / det.quantity;
                det.taxes = det.total_wt - det.total;
                det.service = true;
                det.gift = false;

                Util.updateTotalDetails(det);
                det.taxes = parseFloat((det.total_wt - det.total).toFixed(6));
                
                delete det.id;
                state.editModel.details.push(det);
            });
        },
        setChanged(state: SaleState, value: boolean) {
            state.hasChanges = value;
        },        
    });
}
const saleModule=new SaleModule();
export default saleModule;