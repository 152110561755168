import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductPrice from '../../entities/catalogs/productprice'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ProductPriceState extends ListState<ProductPrice>{
    editModel:ProductPrice;
    product: number;
    reportList: Array<any>;
    filename: string;
}
class ProductPriceModule extends ListModule<ProductPriceState,any,ProductPrice>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<ProductPrice>(),
        loading:false,
        editModel:new ProductPrice(),
        activeList: new Array<ProductPrice>(),
        product: 0,
        reportList: new Array<any>(),
        filename: '',
        path: 'products-prices',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async save(context: ActionContext<ProductPriceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/products-prices', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async batchUpdate(context: ActionContext<ProductPriceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/products-prices/batch-update', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async batchProfitUpdate(context: ActionContext<ProductPriceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/products-prices/batch-profit-update', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },        
        async pricesImport(context: ActionContext<ProductPriceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/products-prices-import',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .catch(e => {
                    context.state.loading = false;
                    return Promise.reject(e);
                });
            context.state.loading = false;
            return response.data.result;
        },
        async reportProductPrices(context: ActionContext<ProductPriceState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/product-prices', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const productpriceModule=new ProductPriceModule();
export default productpriceModule;