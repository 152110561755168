import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import FoodOrder from '../../entities/foods/foodorder'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import appconst from '../../../lib/appconst'
import Customer from '@/store/entities/customers/customer';
import FoodOrderProduct from '@/store/entities/foods/foodorderproduct';
import FoodOrderHistory from '@/store/entities/sales/history';
import PageResult from '@/store/entities/page-result';
import Currency from '@/store/entities/preferences/currency';
import Quotation from '@/store/entities/sales/quotation';

interface FoodOrderState extends ListState<FoodOrder>{
    editModel: FoodOrder;    
    hisLoading: boolean;
    hasChanges: boolean;
    history: Array<FoodOrderHistory>;
    dailyList: Array<any>;
    reportList: Array<any>;
    customers: Array<any>;
    filename: string;
}
class FoodOrderModule extends ListModule<FoodOrderState, any, FoodOrder>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<FoodOrder>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new FoodOrder(),
        activeList: new Array<FoodOrder>(),
        history: new Array<FoodOrderHistory>(),
        dailyList: new Array<any>(),
        reportList: new Array<any>(),
        customers: new Array<any>(),
        filename: '',
        path: 'food-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<FoodOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<FoodOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async confirm(context: ActionContext<FoodOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/food-orders/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
        },
        async delivery(context: ActionContext<FoodOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/food-orders/delivery', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
        },
        async getLastNumber(context: ActionContext<FoodOrderState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/food-orders/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<FoodOrderState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/food-orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async getDaily(context: ActionContext<FoodOrderState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/food-orders/daily', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.dailyList = reponse.data.result;
            context.state.loading = false;
        },
        async reportFoodOrderGeneral(context: ActionContext<FoodOrderState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/food-order-general', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });

            context.state.customers = reponse.data.result.list.map(a => { return { id: a.customer_id, name: a.customer } }).filter(
                (item, index, self) =>
                    index === self.findIndex((t) => t.id === item.id && t.name === item.name)
            );            

            let list = [];
            reponse.data.result.list.forEach(item => {
                let exist = list.filter((detail: any) => {
                    return detail.day == item.day;
                });

                let quantity = (item.quantity == null ? 0 : item.quantity);
                let price = (item.price == null ? 0 : item.price);
                let total = (item.total == null ? 0 : item.total);
                if (exist.length > 0) {
                    exist[0]['quantity_' + item.customer_id] = quantity;
                    exist[0]['price_' + item.customer_id] = price;
                    exist[0]['total_' + item.customer_id] = total;
                    exist[0].quantity += quantity;
                    exist[0].total += total;
                } else {
                    let row = {
                        day: item.day,
                        quantity: 0,
                        total: 0
                    };

                    row['quantity_' + item.customer_id] = quantity;
                    row['price_' + item.customer_id] = price;
                    row['total_' + item.customer_id] = total;
                    row.quantity += quantity;
                    row.total += total;

                    list.push(row);
                }
            });

            context.state.reportList = list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportFoodOrderCustomer(context: ActionContext<FoodOrderState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/food-order-customer', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });

            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: FoodOrderState, model: FoodOrder) {
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 30);
            let serie = Util.abp.setting.get('ORDER_SERIE');

            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY_ORDER');
            currency.conversion_rate = 1;
            state.editModel = Object.assign({});

            state.editModel.customer = Object.assign({});
            state.editModel.customer_id = null;
            state.editModel.customer_name = '';
            state.editModel.customer_document = '';
            state.editModel.organization_document = '';
            state.editModel.organization_name = '';

            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.customer_id = null;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY_ORDER');
            state.editModel.currency = currency;
            state.editModel.exchange_rate = 1;
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00; 
            state.editModel.number = '00000';
            state.editModel.products = new Array<FoodOrderProduct>();
            state.hasChanges = false;
        },
        edit(state: FoodOrderState, model: FoodOrder) {
            state.editModel = model;
            state.editModel.customer = model.customer;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;

            state.editModel.organization_document = model.organization.document_full;
            state.editModel.organization_name = model.organization.fullname;
            state.hasChanges = false;
        },
        updatePayments(state: FoodOrderState, total_paid: any) {
            state.editModel.total_paid = parseFloat(total_paid);
            state.editModel.balance = state.editModel.total_wt - state.editModel.total_paid;
            if (state.editModel.total_paid > 0)
                state.editModel.current_state_id = appconst.sState.PART;

            if (state.editModel.total_paid == state.editModel.total_wt)
                state.editModel.current_state_id = appconst.sState.PAID;
            state.hasChanges = true;
        },
        setTotals(state: FoodOrderState, data: any) {
            if (state.editModel.total_wt != data.total_wt) state.hasChanges = true;
            state.editModel.subtotal = data.subtotal;
            state.editModel.subtotal_wt = data.subtotal_wt;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.balance = data.balance;
            state.editModel.total_paid = data.total_paid;
            if (Math.abs(state.editModel.balance) < 0.01) state.editModel.balance = 0;
        },
        setChanged(state: FoodOrderState, value: boolean) {
            state.hasChanges = value;
        },
        setReportList(state: FoodOrderState, list: Array<any>) {
            state.reportList = list;
            state.totalCount = list.length;
        },
    });
}
const foodorderModule = new FoodOrderModule();
export default foodorderModule;